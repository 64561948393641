import { BrowserModule, } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { Routes, RouterModule } from '@angular/router';
// components
import { AppComponent } from './app.component';
import { LoaderComponent } from './loader/loader.component';

// modules
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

// interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { LoaderInterceptor } from './services/interceptors/loader.interceptor';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ThemeService } from 'ng2-charts';
import { appsetting } from 'src/appsetting';
import { TranslateServicesService } from './services/translate/translate-services.service';
import { TranslatePipe } from './shared/pipe/translate.pipe';
// export function setupTranslateFactory(service: TranslateServicesService): Function {
//   return () => service.use(appsetting.language);
// }











@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    // TranslatePipe,
  ],
  imports: [
 
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    ThemeService,
    TranslatePipe,
    TranslateServicesService,

    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: setupTranslateFactory,
    //   deps: [ TranslateServicesService ],
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



